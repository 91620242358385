const ROUTES = {
	home: () => "/",
	assortment: () => "/assortiment",
	assortmentCategory: (slug: string) => `/assortiment/${slug}`,
	product: (slug: string) => `/product/${slug}`,
	preparation: () => "/bereidingen",

	search: (query: string) => `/zoeken/${query}`,

	accountLogin: () => "/account/inloggen",
	accountRegister: () => "/account/registreren",
	accountForgotPassword: () => "/account/wachtwoord-vergeten",
	account: () => "/account",
	accountOrders: () => "/account/bestellingen",

	checkoutLogin: () => "/bestellen/inloggen",
	checkoutRegister: () => "/bestellen/registreren",
	checkoutDetails: () => "/bestellen/gegevens",
	checkoutDeliveryMethod: () => "/bestellen/levering",
	checkoutTimeslot: () => "/bestellen/levertijd",
	checkoutOverview: () => "/bestellen/overzicht"
} as const;

const CHECKOUT_ROUTES_ORDER = [
	ROUTES.home(),
	ROUTES.checkoutDeliveryMethod(),
	ROUTES.checkoutTimeslot(),
	ROUTES.checkoutOverview()
]


type RouteName = keyof typeof ROUTES;
export const getRoute = <T extends RouteName> (href: T, ...args: Parameters<(typeof ROUTES)[T]>) => {
	return (ROUTES[href] as any)(...args);
};

export const leadingSlashRoute = (route: string) => {
	return route.startsWith("/") ? route : `/${route}`;
};


export const goBackCheckout = (currentRoute: string) => {
	const index = CHECKOUT_ROUTES_ORDER.indexOf(currentRoute);
	const previousRoute = CHECKOUT_ROUTES_ORDER[index - 1];
	return previousRoute;
}
